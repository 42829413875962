<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" xl="3" class="mx-0 px-0 mr-md-2 py-lg-0">
						<alternativeAutocompleteOne
                            :items="suppliers"
                            :valueInput="supplierId"
                            :loading="supplierLoading"
                           
                            @valueChange="(v) => {supplierId = v}"
                            @onChange="(v) => {index()}"
                           
                            :label="'Proveedor'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
					</v-col>
                    <v-col cols="12" md="3" lg="2" xl="2" class="mx-0 px-0 pt-sm-0 mr-md-2 pb-sm-0">
                        <alternativeDatePickerRangeVCalendar
                            :valueInput="{start: dateRange.start_date, end: dateRange.end_date}"
                            @valueChange="(v) => {dateRange.start_date = v.start; dateRange.end_date = v.end; index(); delete serverErrors['date']}"
                            label="Fechas"
                        ></alternativeDatePickerRangeVCalendar>
						
					</v-col>
                    <v-col cols="12" md="2" lg="2" xl="2" class="mx-0 px-0 mr-md-2 py-lg-0 pr-md-10 ">
                        <alternativeAutocompleteOne
                            :items="currencies"
                            :valueInput="selectedCurrency"
                            @valueChange="(v) => {selectedCurrency = v}"
                            @onChange="(v) => {index()}"
                            :label="'Divisa'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                    </v-col>
                    
					<v-spacer></v-spacer>
					<v-col  cols="12" md="3" lg="4" class="mx-0 py-lg-0 ">
                        <v-row justify="end">
                            <iconButton :props="pdfBtnData" class="mr-2 mr-md-2 mb-3 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            <v-card-title class="body-1 font-weight-bold">
                                <v-col>
                                    <v-row v-if="selectedCurrency == 'MXN'" class="primary pt-2">
                                        <v-col class="pt-0 pb-1 text-sm-start text-center">
                                            <span>PAGOS:</span>
                                        </v-col>
                                        <v-col class="pt-0 pb-1 text-sm-end text-center">
                                           
                                                <span>{{totalReport.mxn}}</span>
                                            
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="selectedCurrency == 'USD'" class="primary pt-2">
                                        <v-col class="pt-0 pb-1 text-sm-start text-center">
                                            <span>PAGOS:</span>
                                        </v-col>
                                        <v-col class="pt-0 pb-1 text-sm-end text-center">
                                           
                                                <span>{{totalReport.usd}}</span>
                                            
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-card-title>
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                                    <template v-slot:item.date="props">
                                        <span class="body-2">{{ut.globalDateFormat(props.item.date)}}</span>
                                    </template>
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import PageStateManager from '../../../helpers/PageStateManager';
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
// import debounce from '../../../helpers/debounce';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,
            selectedCurrency: PageStateManager.getState(this.$router.history.current.path, 'selectedCurrency')??"MXN",
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            ut: Utils,
            dateRange: {
                start_date: PageStateManager.getState(this.$router.history.current.path, 'start_date')??Utils.getStartDate(), 
                end_date: PageStateManager.getState(this.$router.history.current.path, 'end_date')?? new Date().toISOString().split('T')[0]
            },
            supplierLoading: false,
            supplierId: PageStateManager.getState(this.$router.history.current.path, 'supplierId')??'',
            suppliers: [],
            lastPage: false,
            suppliersPage: 1,

            timeoutID: null,
            lockSearch: false,

           
            totalReport:{mxn: '$0.00', usd: '$0.00'},
          
			serverErrors: {},
            
			tableProps: {
				headers: [
					{
						text: 'factura',
						align: 'left',
						value: 'folio',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Referencia',
						align: 'left',
						value: 'reference_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cuenta',
						align: 'left',
						value: 'concept',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'Fecha',
						align: 'center',
                        
						value: 'date',
						class: 'table-b-border black--text'
					},
                    {
                        text: 'Monto',
						align: 'right',
                        
						value: 'amount',
						class: 'table-b-border black--text'
					},
                    // {
                    //     text: 'Divisa',
                    //     align: 'left',
                        
                    //     value: 'currency',
                    //     class: 'table-b-border black--text'
                    // },
                    
                   
					
				],
				items: [],
				search: '',
				loading: PageStateManager.getState(this.$router.history.current.path, 'supplierId') ? true : false,
                elevation: '0',
                sortBy: "date",
                sortDesc: "desc"
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				block: false,
                loading: false,
                disabled: true,
                click: ()=>{this.export();}
            },

            pdfBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                disabled: true,
                text: "Generar pdf",
                icon: "mdi-progress-download",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },

            currencies:[
                {value: "USD", text:"USD"},
                {value: "MXN", text:"MXN"}
            ]





			
		}
	},

    watch: {
        [`dateRange.start_date`]: function () {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'start_date', this.dateRange.start_date);
            
        },
        [`dateRange.end_date`]: function () {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'end_date', this.dateRange.end_date);
            
        },
        [`supplierId`]() {
           this.watchSupplierId();
        },
        [`selectedCurrency`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'selectedCurrency', this.selectedCurrency);
         
        }
    },

	mounted(){
        this.getSuppliers();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.watchSupplierId();
		this.index();
	},

    methods: {
        watchSupplierId()
        {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'supplierId', this.supplierId);
            this.pdfBtnData.disabled = true;
            this.exportBtnData.disabled = true;
            if(this.supplierId != "" && this.supplierId != null && this.supplierId != undefined){
                this.pdfBtnData.disabled = false;
                this.exportBtnData.disabled = false;
            }
        },
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
        trySearch(val){
           this.getSuppliers(val.search, val.page);
        },
		index() {
            // // console.log(this.$router)
            if(this.supplierId == '' || this.supplierId == null || this.supplierId == undefined)
                return;

            if(this.dateRange.start_date == '' || this.dateRange.end_date == '' || this.supplierId == '')
                return;


			this.tableProps.loading = true;
            this.totalReport.mxn = this.totalReport.usd = Utils.currencyFormat(0, '');
            this.tableProps.items = [];
           
			//call api
			this.$api.report.reportBalancePaidBySupplier(this.$store.state.business, this.supplierId, this.selectedCurrency, this.dateRange.start_date, this.dateRange.end_date)
				.then((resp) =>{
					console.log(resp);
                   
                    this.totalReport.mxn = Utils.currencyFormat(
                        resp.data.total_mxn
                    , 'MXN');
                    this.totalReport.usd = Utils.currencyFormat(
                        resp.data.total_usd 
                    , 'USD');

					this.tableProps.items = resp.data.data.map((x) => {
                        

						x.amount = Utils.currencyFormat(x.balance, x.currency);

                        x.concept = `${x.concept_name} (${x.account_name??''})`;
                        // x.date = Utils.globalDateFormat(x.date);
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
                    this.lockSearch = false;
                    console.log('finally', this.lockSearch)
				})
			;
           
		},

        // getSuppliers(search = '', page = this.suppliersPage)
        // {
            
        //     this.supplierLoading = true;
        //     this.$api.customer.getSelectPastDueBalance(this.$store.state.business, search, page)
        //         .then((resp) => {
        //             this.customers = [...this.customers, ...resp.data.data];
        //             //this.lastPage = false;
        //             if(page == resp.data.last_page)
        //                 this.lastPage = true;
        //             if(this.customers.length >= 1){
        //                 //this.supplierId = this.customers[0].value;
        //                 this.index();
        //             }
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         })
        //         .finally(() => {
        //             this.supplierLoading = false;
        //         })
        //     ;
        // },
        getSuppliers()
        {
            this.supplierLoading = true;
            this.$api.supplier.getSelectSuppliers(this.$store.state.business)
                .then((resp) => {
                    this.suppliers.push({text: "Todos", value: "all"});
                    this.suppliers.push(...resp.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.supplierLoading = false;
                })
            ;
        },
        onIntersect(val)
        {
            //console.log(val)
            this.getSuppliers(val.search, val.page);
        },

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportBalancePaidBySupplier(this.$store.state.business, this.supplierId, this.selectedCurrency,  this.dateRange.start_date, this.dateRange.end_date)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `pagos_proveedor_${this.supplierId}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },


        pdf()
        {
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.pdfReportBalancePaidBySupplier(this.$store.state.business, this.supplierId, this.selectedCurrency,  this.dateRange.start_date, this.dateRange.end_date)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `pagos_proveedor_${this.supplierId}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        }

    }
}
</script>


<style lang="scss">
    .table-line-height{
        line-height: 1.2rem !important;
    }
</style>