
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ReportService extends BaseApi {


    constructor(){
        super();
    }


    reportBalanceDueReceivableAccounts(business, clientId)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-due/receivable-accounts`, {
            params: {
                customer_id: clientId
            }
        })
        .catch(this.handleErrorResponse);
    }

    exportReportBalanceDueReceivableAccounts(business, clientId)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-due/receivable-accounts/export`,
        {
            params: {
                customer_id: clientId
            },
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportBalanceDueReceivableAccounts(business, clientId)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-due/receivable-accounts/pdf`,
        {
            params: {
                customer_id: clientId
            },
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    reportBalanceDuePayableAccounts(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/balance-due/payable-accounts`,data)
        .catch(this.handleErrorResponse);
    }

    exportReportBalanceDuePayableAccounts(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/balance-due/payable-accounts/export`,data,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportBalanceDuePayableAccounts(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/balance-due/payable-accounts/pdf`,data,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


    reportBalanceDueByCustomers(business, customer, currency)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-due/customers/${customer}/${currency}`)
        .catch(this.handleErrorResponse);
    }

    exportReportBalanceDueByCustomers(business, customer, currency)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-due/customers/${customer}/${currency}/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }
    pdfReportBalanceDueByCustomers(business, customer, currency)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-due/customers/${customer}/${currency}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    reportBalanceDueBySupplier(business, supplier, currency)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-due/providers/${supplier}/${currency}`)
        .catch(this.handleErrorResponse);
    }

    exportReportBalanceDueBySupplier(business, supplier, currency)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-due/providers/${supplier}/${currency}/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }
    
    pdfReportBalanceDueBySupplier(business, supplier, currency)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-due/providers/${supplier}/${currency}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



    reportIncomes(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/incomes`, dates)
        .catch(this.handleErrorResponse);
    }

    exportReportIncomes(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/incomes/export`, dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportIncomes(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/incomes/pdf`, dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


    reportOtherIncomes(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/other-incomes`, dates)
        .catch(this.handleErrorResponse);
    }

    exportReportOtherIncomes(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/other-incomes/export`, dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportOtherIncomes(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/other-incomes/pdf`, dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



    reportPaymentConcept(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/payments/concepts`, data)
        .catch(this.handleErrorResponse);
    }

    exportReportPaymentConcept(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/payments/concepts/export`, data,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportPaymentConcept(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/payments/concepts/pdf`, data,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    reportPaymentBankAccount(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/payments/bank-accounts`, data)
        .catch(this.handleErrorResponse);
    }

    exportReportPaymentBankAccount(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/payments/bank-accounts/export`, data,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportPaymentBankAccount(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/payments/bank-accounts/pdf`, data,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }




    reportBalancePaidByCustomers(business, customer, currency)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-paid/customers/${customer}/${currency}`)
        .catch(this.handleErrorResponse);
    }

    exportReportBalancePaidByCustomers(business, customer, currency)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-paid/customers/${customer}/${currency}/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportBalancePaidByCustomers(business, customer, currency)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-paid/customers/${customer}/${currency}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }
    
    reportBalancePaidBySupplier(business, supplier, currency, dateStart, dateEnd)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-paid/providers`,{
            params:{
                start_date: dateStart,
                end_date:dateEnd,
                provider_id:supplier,
                currency: currency
            }
        })
        .catch(this.handleErrorResponse);
    }

    exportReportBalancePaidBySupplier(business, supplier,currency, dateStart, dateEnd)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-paid/providers/export`,
        {
            params:{
                start_date: dateStart,
                end_date:dateEnd,
                provider_id:supplier,
                currency: currency
            },
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportBalancePaidBySupplier(business, supplier, currency, dateStart, dateEnd)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/balance-paid/providers/pdf`,
        {
            params:{
                start_date: dateStart,
                end_date:dateEnd,
                provider_id:supplier,
                currency: currency
            },
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }





    reportCABalancePayableAccounts(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/balance/ca-payable-accounts`, dates)
        .catch(this.handleErrorResponse);
    }

    exportReportCABalancePayableAccounts(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/balance/ca-payable-accounts/export`, dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportCABalancePayableAccounts(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/balance/ca-payable-accounts/pdf`, dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }




    /**
     * Contractors
    */
    reportContractorsBalanceDueBalance(business, construction_id, supplier)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/contractors/balance/construction/${construction_id}/provider/${supplier}`)
        .catch(this.handleErrorResponse);
    }

    exportReportContractorsBalanceDueBalance(business, construction_id, supplier)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/contractors/balance/construction/${construction_id}/provider/${supplier}/excel`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportContractorsBalanceDueBalance(business, construction_id, supplier)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/contractors/balance/construction/${construction_id}/provider/${supplier}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    exportReportProvidersPayments(business, construction_id, supplier)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/contractors/construction/${construction_id}/provider/${supplier}/payments-excel`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


    reportContractorsIncomes(business, construction_id, customer)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/contractors/receivable-accounts/construction/${construction_id}/customer/${customer}`)
        .catch(this.handleErrorResponse);
    }

    exportReportContractorsIncomes(business, construction_id, customer)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/contractors/receivable-accounts/construction/${construction_id}/customer/${customer}/excel`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportContractorsIncomes(business, construction_id, customer)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/contractors/receivable-accounts/construction/${construction_id}/customer/${customer}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


    /**
     * Receivables
    */
    reportReceivableAccTimelapse(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/receivable-accounts/timelapse`, data)
        .catch(this.handleErrorResponse);
    }

    exportReportReceivableAccTimelapse(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/receivable-accounts/timelapse/export`,dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportReceivableAccTimelapse(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/receivable-accounts/timelapse/pdf`,dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    /**
     * Receivables by customer
    */
    reportReceivableAccByCustomer(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/balance-due/receivable-accounts/customers`, dates)
        .catch(this.handleErrorResponse);
    }

    exportReportReceivableAccByCustomer(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/balance-due/receivable-accounts/customers/export`,dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportReceivableAccByCustomer(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/balance-due/receivable-accounts/customers/pdf`,dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



    reportOutcome(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/outcomes`, dates)
        .catch(this.handleErrorResponse);
    }

    exportReportOutcome(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/outcomes/export`, dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportOutcome(business, dates)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/outcomes/pdf`, dates,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    /**
     * payment history
    */
    reportPaymentHistory(business, startDate, endDate, customerId, currency)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/customers/payment-history`,{
            start_date: startDate,
            end_date: endDate,
            customer_id: customerId,
            currency: currency
        })
        .catch(this.handleErrorResponse);
    }


    exportReportPaymentHistory(business, startDate, endDate, customerId, currency)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/customers/payment-history/excel`,{
            start_date: startDate,
            end_date: endDate,
            customer_id: customerId,
            currency: currency
        },
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    PdfReportPaymentHistory(business, startDate, endDate, customerId, currency)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/customers/payment-history/pdf`,{
            start_date: startDate,
            end_date: endDate,
            customer_id: customerId,
            currency: currency
        },
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }




    getFoliosReceivableAccounts(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/catalogs/folios-receivable-account`)
        .catch(this.handleErrorResponse);
    }


    /**
     * billing receivable accounts by folio
    */
    reportBillingReceivableAccounts(business, start_folio, end_folio)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/billing/receivable-accounts`,{
            start_folio,
            end_folio
        })
        .catch(this.handleErrorResponse);
    }


    exportReportBillingReceivableAccounts(business, start_folio, end_folio)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/billing/receivable-accounts/export`,{
            start_folio,
            end_folio
        },
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    pdfReportBillingReceivableAccounts(business, start_folio, end_folio)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/billing/receivable-accounts/pdf`,{
            start_folio,
            end_folio
        },
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



     /**
     * billing receivable accounts by folio
    */
    reportLoansPending(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/loans/pending`)
        .catch(this.handleErrorResponse);
    }
 
 
    exportReportLoansPending(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/loans/pending/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }
 
    PdfReportLoansPending(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/reports/loans/pending/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



    /**
     * Income statements
    */
    reportIncomeStatement(business, year, month)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/reports/income-statement`,{
            year,
            month
        })
        .catch(this.handleErrorResponse);
    }
}